import { watch } from 'vue'
import { feature } from 'topojson-client'
import center from '@turf/center'
import { orderBy } from 'natural-orderby'
import { polygonsConfig } from '@/components/map/config'
import { useMapStore } from '@/store/map'

const polygonsID = 's-polygons'
const linesID = 's-lines'
const polygonsCenterID = 's-center'

export const usePolygonsLayer = (mapgl) => {
  const mapStore = useMapStore()
  let polygonsCenter = null
  let geoJSON = null
  let labelsGeoJSON = null

  const loadAndAddPolygonsLabels = async () => {
    await loadPolygonsLabels()
    await loadPolygons()
    saveZonesToStore()
    getPolygonsCenters()

    addPolygons()
    addPolygonsCenters()
    addPolygonsHover()
  }
  const loadPolygons = async () => {
    try {
      const response = await fetch('/polygons/msk_rounded.topojson')
      const topoJSON = await response.json()

      convertToGeojson(topoJSON)
    } catch (e) {
      console.log(e)
    }
  }

  const loadPolygonsLabels = async () => {
    try {
      const response = await fetch('/polygons/labels.geojson')
      labelsGeoJSON = await response.json()
    } catch (e) {
      console.log(e)
    }
  }

  const convertToGeojson = (topoJSON) => {
    geoJSON = feature(topoJSON, topoJSON.objects.data)
    geoJSON.features.forEach((feature) => {
      feature.properties.zone_color = feature.properties.zone_color.substring(
        0,
        2
      )

      // const matchingZone = mapStore.mapZones.find(
      //   (zone) => zone.id === feature.properties.id
      // )

      // if (matchingZone) {
      //   let { zone_name, zone_name_width } = matchingZone
      //   zone_name_width = zone_name_width ?? ''
      //   feature.properties.name = `${zone_name} ${zone_name_width}`
      // }
    })
  }

  const saveZonesToStore = () => {
    const mapZones = Array.isArray(geoJSON?.features)
      ? geoJSON.features.map((feature) => {
          return {
            id: feature.properties.id,
            zone_name: feature.properties.zone_name,
            zone_name_width: feature.properties.zone_name_width,
            fullZoneName: `${feature.properties.zone_name}${
              feature.properties.zone_name_width
                ? `, ${feature.properties.zone_name_width}`
                : ''
            }`
          }
        })
      : []

    const uniqueIds = {}
    if (!mapZones.length) return

    const filteredZones = mapZones.filter((item) => {
      if (!uniqueIds[item.id]) {
        uniqueIds[item.id] = item.id
        return item
      }
    })

    const sortedZones = orderBy(filteredZones, [(v) => v.fullZoneName], ['asc'])

    mapStore.SET_STATE_FIELD('mapZones', sortedZones)
  }

  const getPolygonsCenters = () => {
    polygonsCenter = labelsGeoJSON?.features.map((coord) => {
      const centerCoord = center(coord)
      centerCoord.properties.zone_name = coord.properties.zone_name
      centerCoord.properties.zone_name_width =
        coord.properties.zone_name_width || ''
      return centerCoord
    })
  }

  const addPolygons = () => {
    if (mapgl.value.getSource(polygonsID)) return

    mapgl.value.addSource(polygonsID, {
      type: 'geojson',
      data: geoJSON
    })

    mapgl.value.addLayer({
      id: polygonsID,
      source: polygonsID,
      ...polygonsConfig.fill
    })

    mapgl.value.addLayer({
      id: linesID,
      source: polygonsID,
      ...polygonsConfig.line
    })
  }

  const addPolygonsCenters = () => {
    if (mapgl.value.getSource(polygonsCenterID)) return

    mapgl.value.addSource(polygonsCenterID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: polygonsCenter
      }
    })

    mapgl.value.addLayer({
      id: polygonsCenterID,
      source: polygonsCenterID,
      ...polygonsConfig.label
    })
  }

  const addPolygonsHover = () => {
    mapgl.value.on('mousemove', polygonsID, (e) => {
      const features = mapgl.value.queryRenderedFeatures(e.point, {
        layers: [polygonsID]
      })
      mapgl.value.getCanvas().style.cursor = 'pointer'

      if (features.length > 0) {
        const findedZone = mapStore.mapZones.find((zone) => {
          return zone.id === features[0].properties.id
        })

        if (!findedZone) {
          mapStore.SET_STATE_FIELD('hoveredPolygon', null)
          return
        }

        mapStore.SET_STATE_FIELD('hoveredPolygon', {
          zone_name: findedZone.zone_name,
          zone_name_width: findedZone.zone_name_width,
          points: e.point
        })
      } else {
        mapStore.SET_STATE_FIELD('hoveredPolygon', null)
      }
    })

    mapgl.value.on('mouseleave', polygonsID, () => {
      mapgl.value.getCanvas().style.cursor = ''
      mapStore.SET_STATE_FIELD('hoveredPolygon', null)
    })
  }

  const togglePolygons = () => {
    const value = mapStore.isShowPolygons ? 'visible' : 'none'

    mapgl.value.setLayoutProperty(polygonsID, 'visibility', value)
    mapgl.value.setLayoutProperty(linesID, 'visibility', value)
    mapgl.value.setLayoutProperty(polygonsCenterID, 'visibility', value)
  }

  watch(
    () => mapStore.isShowPolygons,
    () => {
      togglePolygons()
    }
  )

  watch(
    () => mapStore.activeBaselayer,
    () => {
      mapgl.value.once('style.load', () => {
        addPolygons()
        addPolygonsCenters()
        togglePolygons()
      })
    }
  )

  return {
    loadAndAddPolygonsLabels
  }
}
