import Handsontable from 'handsontable'
import {
  tableConfig,
  WGSMSKHeaders,
  MSKWGSHeaders,
  columnsConfig,
  decimalColumnsConfig
} from '@/config/table'
import { getDataFromLS } from '@/utils'
import { onMounted, ref } from 'vue'

export const useTable = () => {
  let options = {
    nestedHeaders: WGSMSKHeaders,
    columns: columnsConfig
  }

  const selectValue = getDataFromLS('selectValue')

  if (selectValue?.id === 'MSK-WGS') {
    options = {
      nestedHeaders: MSKWGSHeaders,
      columns: decimalColumnsConfig
    }
  } else {
    options = {
      nestedHeaders: WGSMSKHeaders,
      columns: columnsConfig
    }
  }

  const table = ref()

  onMounted(() => {
    const container = document.querySelector('#table')

    table.value = new Handsontable(container, {
      ...tableConfig,
      ...options
    })
  })

  return {
    table
  }
}
